import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

// services
import { sendToCT } from "../../../services/Clevertap";

// component
import VideoModal from "../Banner/VideoModal";

// styles
import * as styles from "./BannerV2.module.css";

const BannerV2 = props => {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const toggleVideo = () => {
    setShowVideoModal(!showVideoModal);
  };

  return (
    <div className="bgLightBlue">
      <div className="container">
        <div className="row">
          <div className={`col-12 text-center ${styles.bannerv2__hdng}`}>
            <h3>
              Beating metabolic diseases is now possible with your Whole Body
              Digital Twin™
            </h3>
            {/* <h3> with your Whole Body Digital Twin™</h3> */}
          </div>
        </div>
        <div className="row">
          <div className={`col-12 text-center ${styles.bannerv2__subHdng}`}>
            <p>
              Prediabetes | Diabetes | Obesity | PCOD | Metabolic Wellness for
              Preventive Care
            </p>
          </div>
        </div>
        {/* Line */}
        <div className={styles.bannerv2__line}></div>
        <div className="row">
          <div className={`col-12 text-center ${styles.bannerv2__para}`}>
            <p>
              The Twin Health Program uses advanced Whole Body Digital Twin™
              technology to help heal your disrupted metabolism — the root cause
              of many chronic diseases.
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className={`col-12 text-center ${styles.bannerv2__videoImg}`}>
            <StaticImage
              src="../../../images/home/banner_v2.png"
              alt="video"
              placeholder="blurred"
              loading="lazy"
            />
            <div
              className={styles.bannerv2__playIcon}
              role="presentation"
              onClick={() => {
                toggleVideo();
                sendToCT("Video1_clicked", {
                  action: "user clicks on video 1",
                });
              }}
            >
              <StaticImage
                src="../../../images/home/play_icon_v2.png"
                alt="video"
                placeholder="blurred"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`container`}>
        <div className={`row  ${styles.bannerv2__cardRow}`}>
          <h3>With Twin, Metabolic disease doesn’t stand a chance.</h3>
          <h5>40000+ Members Benefitted</h5>
          <div>
            <div className={`row ${styles.bannerv2__cardRow__cards}`}>
              {/* 1st Card */}
              <div
                className={`col-md-3 col-sm-6 ${styles.bannerv2__cardRow__card1}`}
              >
                <div className={styles.bannerv2__cardRow__card1Icn}>
                  <StaticImage
                    src="../../../images/home/bannerv2_card_icn1.png"
                    alt="icon"
                    placeholder="blurred"
                    loading="lazy"
                  />
                </div>
                <h5>100%</h5>
                <p>Improved their Metabolic Health</p>
              </div>
              {/* 2nd card */}
              <div
                className={`col-md-3 col-sm-6 ${styles.bannerv2__cardRow__card2}`}
              >
                <div className={styles.bannerv2__cardRow__card1Icn}>
                  <StaticImage
                    src="../../../images/home/bannerv2_card_icn2.png"
                    alt="icon"
                    placeholder="blurred"
                    loading="lazy"
                  />
                </div>
                <h5>76.5%</h5>
                <p>Diabetes Remission*</p>
              </div>
              {/* 3rd card */}
              <div
                className={`col-md-3 col-sm-6 ${styles.bannerv2__cardRow__card3}`}
              >
                <div className={styles.bannerv2__cardRow__card1Icn}>
                  <StaticImage
                    src="../../../images/home/bannerv2_card_icn3.png"
                    alt="icon"
                    placeholder="blurred"
                    loading="lazy"
                  />
                </div>
                <h5>3.1%</h5>
                <p>A1c Reduction</p>
              </div>
              {/* 4th card */}
              <div
                className={`col-md-3 col-sm-6 ${styles.bannerv2__cardRow__card4}`}
              >
                <div className={styles.bannerv2__cardRow__card1Icn}>
                  <StaticImage
                    src="../../../images/home/bannerv2_card_icn4.png"
                    alt="icon"
                    placeholder="blurred"
                    loading="lazy"
                  />
                </div>
                <h5>13.45%</h5>
                <p>Weight Loss**</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={`col-12 ${styles.bannerv2__bottomPara}`}>
            <p>
              *Diabetes Technology & Therapeutics, Volume: 25, Issue: S2, Page
              A-88, February 2023
            </p>
            <p>
              **Diabetes Technology & Therapeutics, Volume: 25, Issue: S2, Page
              A-41, February 2023
            </p>
          </div>
        </div>
      </div>

      <VideoModal
        show={showVideoModal}
        onHide={toggleVideo}
        videoLink={props.videoLink}
      />
    </div>
  );
};

export default BannerV2;
